/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import styles from './ListThumbnail.module.scss';
import ListThumbnailFooter from './ListThumbnailFooter';
import ListThumbnailImages from './ListThumbnailImages';
import useFormatDateText from '@hooks/useFormatDateText';
import useIsCustomerInStore from '@hooks/useIsCustomerInStore';
import useTranslations from 'context/Translations';

import { SourceList } from 'apis/types';

type ListThumbnailProps = {
    list: SourceList;
};
const ListThumbnail: React.FC<ListThumbnailProps> = ({ list }) => {
    const isCustomerInStore = useIsCustomerInStore();
    const translate = useTranslations();
    const formatDateText = useFormatDateText();
    const dateText = formatDateText(list.updated);

    const linkHref = isCustomerInStore
        ? `${list.listId}?view=store`
        : `${list.listId}?view=retail`;
    const itemImages = list.items.map(item => ({
        images: item.product?.images || [],
        alt: item.product?.name || '',
    }));

    const designImages = list.designs.map(design => ({
        images: design.images || [],
        alt: design.configurationId || '',
    }));
    return (
        <button
            type="button"
            className={styles.container}
            onClick={() => (window.location.href = linkHref)}
            aria-label={`${translate('LISTOVERVIEW_LISTS_THUMBNAIL_ARIA', {
                listName: list.name,
            })}, ${dateText}`}
        >
            <div className={styles.wrapper} data-testid="select-list-item">
                <ListThumbnailFooter list={list} />
                <ListThumbnailImages items={[...itemImages, ...designImages]} />
            </div>
        </button>
    );
};

export default ListThumbnail;
