import React, { useEffect } from 'react';

import CreateListBlock from './CreateListBlock';
import styles from './ListOverview.module.scss';
import ListThumbnail from './ListThumbnails';
import ListThumbnailSkeleton from './ListThumbnails/ListThumbnailSkeleton';
import { useModal } from '@components/Modals/useModal';
import { useAnalytics } from '@utils/analytics';
import { sortLists } from 'lib/client/sortLists/sortLists';

import useGetLists from 'apis/list/hooks/useGetLists';

import { useSettings } from 'hooks/useSettings';

export function ListOverview() {
    const { showModal } = useModal();
    const { data: { lists = [] } = {}, isLoading } = useGetLists({
        select: data => ({
            lists: sortLists(data?.lists),
        }),
    });
    const { maxLists } = useSettings();
    const { logEvent } = useAnalytics();

    useEffect(() => {
        if (!isLoading) {
            logEvent('favourites', 'favourites_view', {
                numberOfLists: lists.length,
                totalListsValue: Number(
                    lists
                        .reduce(
                            (acc, list) =>
                                acc +
                                (list?.totals?.prices?.online?.regular
                                    ?.totalInclDiscount?.exclTax ?? 0),
                            0
                        )
                        .toFixed(2)
                ),
                totalItemsQuantity: lists.reduce(
                    (acc, list) => acc + list.quantity,
                    0
                ),
            });
        }
    }, [isLoading, lists, logEvent]);

    return (
        <>
            <CreateListBlock
                disabled={lists.length >= maxLists}
                loading={isLoading}
                onCreateList={() => showModal('CreateListModal', {})}
            />

            <div className={styles.wrapper}>
                {isLoading ? (
                    <ListThumbnailSkeleton />
                ) : (
                    <>
                        {lists.map(list => (
                            <ListThumbnail key={list.listId} list={list} />
                        ))}
                    </>
                )}
            </div>
        </>
    );
}
