import React from 'react';

import styles from './CountBadge.module.scss';
import Text from '@skapa/Text';

type Props = { count: number };

const CountBadge: React.FC<Props> = ({ count }) => {
    return (
        <div className={styles.wrapper}>
            <Text headingSize="xs" className={styles.countBadge}>
                {count}
            </Text>
        </div>
    );
};

export default CountBadge;
