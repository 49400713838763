import React from 'react';

import styles from './CreateListBlock.module.scss';
import useAuth from '@hooks/useAuth';
import { useSettings } from '@hooks/useSettings';
import Button from '@skapa/Button';
import Text from '@skapa/Text';
import { setLocationHref } from '@utils/window';
import useTranslations from 'context/Translations';

type CreateListBlockProps = {
    loading: boolean;
    onCreateList: () => void;
    disabled: boolean;
};

const CreateListBlock: React.FC<CreateListBlockProps> = ({
    loading,
    disabled,
    onCreateList,
}) => {
    const translate = useTranslations();
    const { loginUrl } = useSettings();
    const { isLoggedIn, isAuthenticating } = useAuth();

    return (
        <div className={styles.wrapper}>
            <Text headingSize="l" tagName="h1" className={styles.marginBottom}>
                {translate('LISTOVERVIEW_NAVIGATION_LISTS')}
            </Text>
            <Text bodySize="m" tagName="p" className={styles.marginBottom}>
                {translate('LISTOVERVIEW_LISTS_CONTENT')}
            </Text>
            {!isLoggedIn && !isAuthenticating && (
                <Text bodySize="m" tagName="p" className={styles.marginBottom}>
                    {translate('LISTOVERVIEW_LISTS_LOGIN_CONTENT')}
                </Text>
            )}
            <div className={styles.buttonGroup}>
                <Button
                    loading={loading}
                    type="primary"
                    data-testid="create-new-list"
                    text={translate('CREATEMODAL_LIST_FOOTER_BUTTON')}
                    disabled={disabled}
                    onClick={onCreateList}
                    small
                />
                {!isLoggedIn && (
                    <Button
                        loading={isAuthenticating}
                        type="secondary"
                        text={translate('SESSION_EXPIRED_PROMPT_BUTTON')}
                        onClick={() => setLocationHref(loginUrl)}
                        small
                    />
                )}
            </div>
        </div>
    );
};

export default CreateListBlock;
