import React from 'react';

import styles from './ListThumbnailMissingImage.module.scss';
import Text from '@skapa/Text';
import useTranslations from 'context/Translations';

const ListThumbnailMissingImage = () => {
    const translate = useTranslations();

    return (
        <div className={styles.wrapper}>
            <Text bodySize="m" tagName="span">
                {translate('LISTOVERVIEW_LISTS_THUMBNAIL_EMPTY')}
            </Text>
        </div>
    );
};

export default ListThumbnailMissingImage;
