import React from 'react';

import styles from './ListThumbnailFooter.module.scss';
import { useModal } from '@components/Modals/useModal';
import ellipsisIcon from '@ingka/ssr-icon/paths/ellipses-vertical';
import Text from '@skapa/Text';
import useTranslations from 'context/Translations';

import { SourceList } from 'apis/types';

import useFormatDateText from 'hooks/useFormatDateText';

import Button from 'skapa/Button';

type ListThumbnailFooterProps = {
    list: SourceList;
};

const ListThumbnailFooter: React.FC<ListThumbnailFooterProps> = ({
    list,
}: ListThumbnailFooterProps) => {
    const { showModal } = useModal();
    const translate = useTranslations();
    const formatDateText = useFormatDateText();
    const dateText = formatDateText(list.updated);

    return (
        <div className={styles.wrapper}>
            <div className={styles.listInfo}>
                <Text headingSize="s" tagName="h4" className={styles.listName}>
                    {list.name}
                </Text>
                <Text bodySize="m" tagName="p">
                    {dateText}
                </Text>
            </div>

            <Button
                small
                iconOnly
                type="tertiary"
                ssrIcon={ellipsisIcon}
                aria-label={translate('LISTDETAIL_SETTINGS_TITLE2')}
                onClick={e => {
                    e.stopPropagation();
                    showModal('ListMenu', {
                        list,
                        isLandingPage: true,
                    });
                }}
            />
        </div>
    );
};

export default ListThumbnailFooter;
